:root {
    --border-radius: 20px;
    --arrow-bullet-image-container-width: 20px;
}

.arrow-cols-4 {
    width: 23.5%;
}

.arrow-cols-3 {
    width: 32%;
}

.arrow-cols-2 {
    width: 49%;
}

.arrow-cols-1 {
    width: 99%;
}

.cols-4 {
    width: 22%;
}

.cols-3 {
    width: 30%;
}

.cols-2 {
    width: 46%;
}

.cols-1 {
    width: 95%;
}

.inidividual-bullet-point-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    /* background-color: #fff4e0; */
    background-color: #ffebb1;
    border-radius: var(--border-radius);
    transition: box-shadow 200ms ease-in-out;
}

.inidividual-bullet-point-container:hover {
    box-shadow: 1px 1px 14px lightgray;
}

.number-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    max-width: 50px;
    margin: 10px
}

.number-container > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ivory;
    border-radius: var(--border-radius);
    width: 100%
}

.arrow-bullet-container {
    background-color: #ffebb1;
}

.arrow-img-container {
    width: var(--arrow-bullet-image-container-width);
}

.arrow-img {
    background-color: #e6e6e6;
    height: 100%;
    width: 90%;
    transition: background-color 500ms ease-in-out, transform 500ms ease-in-out;
}

.arrow-img:nth-child(2n) {
    background-color: #ffebb1;
}

.arrow-bullet-container:hover .arrow-img {
    transform: scaleX(1.2);
    transform-origin: left;
    background-color: #fec415;
}

.arrow-bullet-content {
    width: calc(100% - var(--arrow-bullet-image-container-width))
}


@keyframes bounce {
    0% {
        transform: scaleX(1);
        transform-origin: left;
    }
    50% {
        transform: scaleX(0.5);
        transform-origin: left;
    }
    100% {
        transform: scaleX(1);
        transform-origin: left;
    }
}

@media (max-width: 720px) {
    .cols-4, .cols-3{
        width: 45%;
    }
    .arrow-cols-4, .arrow-cols-3, .arrow-cols-2{
        width: 99%;
    }
}