$primary: #efc230;
$secondary: #edf1fc;
$grey: #d4d4d4;

// -----fonts
@import "node_modules/bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&family=Raleway:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

html {
  //   font-size: 62.5%; // 1rem = 10px
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

body {
  min-width: 300px !important;
  font-size: 14px !important;
  font-family: "Lato", sans-serif;
  // color: $fontColor;
  // font-family: "Poppins", sans-serif;
  // font-family: "Source Sans Pro", sans-serif;
  // font-family: "Raleway", sans-serif;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cursor-pointer {
  cursor: pointer !important;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1919px) {
  body {
    font-size: 17px !important;
  }
}

@media only screen and (min-width: 1920px) {
  body {
    font-size: 18px !important;
  }
}

.text-small {
  font-size: 14px;
}

.fw-500 {
  font-weight: 500 !important;
}

// ----------font sizes----------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 600 !important;
}

p,
.form-check-label {
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 40px !important;
  }

  h2 {
    font-size: 32px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }

  .subtitle {
    font-size: 18px !important;
  }

  p,
  .text1 {
    font-size: 16px;
  }

  .text2 {
    font-size: 14px;
  }

  .caption {
    font-size: 10px;
  }

  .fontSizeAboutUs {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1919px) {
  h1 {
    font-size: 48px !important;
  }

  h2 {
    font-size: 40px !important;
  }

  h3 {
    font-size: 32px !important;
  }

  h4 {
    font-size: 24px !important;
  }

  .subtitle {
    font-size: 20px !important;
  }

  p,
  .text1 {
    font-size: 17px !important;
  }

  .text2 {
    font-size: 14px !important;
  }

  .caption {
    font-size: 10px !important;
  }

  .fontSizeAboutUs {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1920px) {
  h1 {
    font-size: 64px !important;
  }

  h2 {
    font-size: 48px !important;
  }

  h3 {
    font-size: 40px !important;
  }

  h4 {
    font-size: 32px !important;
  }

  .subtitle {
    font-size: 24px !important;
  }

  p,
  .text1 {
    font-size: 18px;
  }

  .text2 {
    font-size: 16px;
  }

  .caption {
    font-size: 10px;
  }

  .fontSizeAboutUs {
    font-size: 24px;
  }
}

.row,
.col {
  margin: 0 !important;
}

.overflowX-scroll {
  overflow-x: "scroll" !important;
}

.overflowY-scroll {
  overflow-y: "scroll" !important;
}

// -----no focus
.focus-none:focus,
.focus-none:visited .focus-none:focus:focus-visible {
  // border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
}

// -----input focus
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  border-color: $primary !important;
  box-shadow: none !important;
  outline: none !important;
}

// -----checkbox accent color
.form-check-input:checked,
.form-check-input::after {
  background-color: $primary !important;
  border-color: $primary !important;
  box-shadow: none !important;
  outline: none !important;
  box-sizing: unset !important;
}

.content-wrapper {
  max-width: 1300px;
  padding: 0 10px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 600px) {
  .content-wrapper {
    //     max-width: 584px;
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

@media only screen and (min-width: 600px) {
  .content-wrapper {
    //     max-width: 572px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (min-width: 768px) {
  .content-wrapper {
    //     max-width: 768px;
    padding-left: 26px !important;
    padding-right: 26px !important;
  }
}

@media only screen and (min-width: 992px) {
  .content-wrapper {
    //     max-width: 964px;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .content-wrapper {
    //     max-width: 1160px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

// @media (min-width: 1400px) {
//   .content-wrapper {
////     max-width: 1320px;
//  padding: 0 10px;
//   }
// }

// @media only screen and (max-width: 600px) {...}
// @media only screen and (min-width: 600px) {...}
// @media only screen and (min-width: 768px) {...}
// @media only screen and (min-width: 992px) {...}
// @media only screen and (min-width: 1200px) {...}

.border-radius-20 {
  border-radius: 20px !important;
}

.btn-radius {
  border-radius: 4px !important;
}

.bg-primary {
  background: $primary !important;
}

.bg-grey {
  background: $grey !important;
}

.border-primary {
  border: 1px solid $primary !important;
}

.border-grey {
  border: 1px solid $grey !important;
}

.text-primary {
  color: $primary !important;
}

.text-grey {
  color: $grey !important;
}

.max-lines-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.max-lines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-lines-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

///// scrollbar-none
.scrollbar-none::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

// * {
//   scrollbar-width: thin;
//   scrollbar-color: $primary #f1f1f1;
// }

// body::-webkit-scrollbar,
// *::-webkit-scrollbar {
//   width: 10px;
// }

// ::-webkit-scrollbar-track,
// *::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// body::-webkit-scrollbar-thumb,
// *::-webkit-scrollbar-thumb {
//   background: $primary;
// }

// body::-webkit-scrollbar-thumb:hover,
// *::-webkit-scrollbar-thumb:hover {
//   background: $primary;
// }

// .inner-dropdown > #collapsible-nav-dropdown.dropdown-toggle {
//   background-color: #000000;
// }

#collapsible-nav-dropdown.dropdown-toggle::after,
.inner-dropdown > #collapsible-nav-dropdown.dropdown-toggle::after {
  // display: inline-block;
  // margin-left: 0.255em;
  // vertical-align: 0.255em;
  // content: "";
  // border-top: 0.3em solid;
  // border-right: 0.3em solid transparent;
  // border-bottom: 0;
  // border-left: 0.3em solid transparent;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

@media only screen and (min-width: 992px) {
  .outer-dropdown > .dropdown-menu {
    border-radius: 0 !important;
    margin-top: 20px !important;
    max-width: 300px;
  }
}

@media only screen and (min-width: 992px) {
  .inner-dropdown > .dropdown-menu.show {
    display: none;
  }
}

.inner-dropdown > .dropdown-menu.show {
  border-width: 0px !important;
}

.inner-dropdown > .navbar-toggler-icon {
  display: none !important;
}

.active-nav {
  border-bottom: 3px solid $primary !important;
}

.active-dropdown .dropdown-toggle.nav-link {
  border-bottom: 3px solid $primary !important;
}

.active-tab {
  border-bottom: 3px solid $primary !important;
}

.nested-dropdown-active-tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  // left: 50px;
  width: "85%";
  border-bottom: 3px solid $primary !important;
}

// @media only screen and (max-width: 768px) {
//   .dropdown-toggle.show.nav-link {
//     border-bottom: 3px solid $primary !important;
//   }
// }

// ----- accordion -----
.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: white !important;
  background-color: $primary !important;
}

// ----- tabs -----
.nav-tabs .nav-link {
  flex-grow: 1 !important;
  height: 100% !important;
  color: #000 !important;
}

.nav-tabs .nav-link.active {
  border-width: 0px !important;
  border-color: transparent !important;
  border-bottom: 3px solid $primary !important;
  color: $primary !important;
}

// .slick-prev {
//   z-index: 1000 !important;
//   background: #000;
// }
// .slick-next {
//   z-index: 1000 !important;
//   background: #000;
// }

// ----- client-section -----
.diamond-card {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%) !important;
  position: relative;
  background-color: $primary;
  border: 0px 2px 2px 0 solid $primary;
}

// carousel dots
.slick-dots {
  bottom: -40px;
}

.slick-dots li button::before {
  color: $primary !important;
  font-size: 8px !important;
}

@media only screen and (max-width: 576px) {
  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li {
    margin: 0px !important;
  }

  .slick-dots li button::before {
    font-size: 6px !important;
  }
}

// ----- data lists -----
ul {
  padding-left: 1rem;
}

// ul.commitment {
//   list-style-image: url('assets/images/target.png');
//   list-style-position: outside;
// }

// ----- table -----
#dynamic-table,
#vertical-table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#dynamic-table th,
#vertical-table th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 8px;
}

#dynamic-table tr:nth-child(even),
#vertical-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#dynamic-table td,
#vertical-table td {
  padding: 8px;
}

.carousel-control-prev.carousel-control-prev-icon,
.carousel-control-next.carousel-control-next-icon {
  color: #ffffff !important;
}

.card-gradient-background {
  // background: linear-gradient(180deg, #fff, #efc230);
  background: linear-gradient(180deg, #fff, #d0dee5);
}

.text-decoration-none {
  text-decoration: none !important;
}

.blur-text-background {
  // text-shadow: 0 0 0 #555555, 0px 0px 10px #000000;
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
}

.text-justify {
  text-align: justify !important;
}

.float-right {
  float: right !important;
}